import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CrmOnceSubject } from 'common-module/core';
import { CrmDictionary, CrmSafeAny } from 'common-module/core/types';
import {
  CrmEndpointDecorator,
  CrmEndpointListResponse,
} from 'common-module/endpoint';
import { CrmMessageService } from 'common-module/message';
import { CrmUserEndpoint, CrmUserService } from 'common-module/user';
import {
  EMPTY,
  finalize,
  map,
  Observable,
  of,
  race,
  Subject,
  take,
  tap,
  throwError,
} from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { logoutWithReturnUrl } from 'common-module/auth';

import { environment } from '~/environments/environment';
import { ErrorTrackingBuilderService } from '~/shared/modules/tracking/services/error-tracking-builder.service';
import { BroadcastChannelService } from '~/shared/services/broadcast-channel.service';
import { TitleService } from '~/shared/services/title.service';
import { safeListUsersByIDs } from '~/shared/utils/list/safe-list';
import { LocalStorage, LocalStorageKeys } from '~/shared/utils/local-storage';
import { CurrentClinicService } from '~/shared/services/current-clinic.service';

import { ClinicsApiService } from '../clinics/clinics-api.service';

import { UserLoginModel } from './user-login.model';
import { UserResponseTransformer } from './user-response.transformer';
import { UserModel, UserRole } from './user.model';

@Injectable({ providedIn: 'root' })
export class UserApiService extends CrmUserService<UserModel> {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'users',
    endpoint: CrmUserEndpoint,
    responseTransformer: UserResponseTransformer,
  })
  protected override endpoint!: CrmUserEndpoint<UserModel>;

  login$ = new Subject<void>();
  profile$ = new CrmOnceSubject<void>();
  logout$ = new Subject<void>();

  private _token?: string;

  private trackingBuilder = inject(ErrorTrackingBuilderService);
  private messageService = inject(CrmMessageService);
  private clinic = inject(ClinicsApiService);
  private title = inject(TitleService);
  private broadcast = inject(BroadcastChannelService);
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private currentClinic = inject(CurrentClinicService);

  constructor() {
    super();

    this.broadcast.subscribe('logout').subscribe(() => {
      logoutWithReturnUrl({
        userService: this,
        router: this.router,
        authConfig: { authPath: 'auth' },
      });
    });

    this.broadcast.subscribe('login').subscribe(() => {
      const { queryParams } = this.route.snapshot;

      if (queryParams['returnUrl']) {
        this.router.navigateByUrl(queryParams['returnUrl']);
      }
    });

    race(this.profile$, this.login$)
      .pipe(
        take(1),
        switchMap(() => this.clinic.getProfile()),
      )
      .subscribe(({ name }) => this.title.setClinicName(name));
  }

  get token(): string | undefined {
    return this._token;
  }

  override getProfile() {
    return super.getProfile().pipe(tap(() => this.profile$.next()));
  }

  override login<Body>(_body: Body): Observable<UserModel> {
    const body = _body as UserLoginModel;
    return super.login(this.withDomain(body)).pipe(
      catchError((err) => throwError(() => err)),
      tap((user) =>
        this.trackingBuilder.setUserLocalData({
          id: user._id,
          loggedAt: new Date(),
        }),
      ),
      tap((user) => {
        if (user && body.token) {
          LocalStorage.save(LocalStorageKeys.TOKEN, body.token);
        } else {
          LocalStorage.remove(LocalStorageKeys.TOKEN);
        }
        this._token = LocalStorage.get(LocalStorageKeys.TOKEN);
        this.login$.next();
        this.broadcast.post('login');
      }),
    );
  }

  override logout(): Observable<string> {
    return this.user$.pipe(
      switchMap(() =>
        super.logout().pipe(
          tap(() => {
            this.broadcast.post('logout');
          }),
        ),
      ),
      catchError(() => EMPTY),
      finalize(() => {
        this.logout$.next();
        this.messageService.success('auth.login.messages.successfulLogout');
        LocalStorage.remove(LocalStorageKeys.TOKEN);
        delete this._token;
        this.currentClinic.invalidate();
        this.router.navigate(['auth', 'login'], {
          queryParamsHandling: 'preserve',
        });
      }),
    );
  }

  override resetPassword<Body>(body: Body): Observable<UserModel> {
    return super.resetPassword(this.withDomain(body));
  }

  override updateUser<Body>(
    id: string,
    body: Body,
    options?: object,
  ): Observable<UserModel> {
    return super
      .updateUser(id, body, options)
      .pipe(map((result) => ({ ...result, _id: id })));
  }

  hasAnyRole$(expectedRoles: UserRole[]): Observable<boolean> {
    return this.user$.pipe(
      map(({ roles }) => {
        return expectedRoles.some((role) => roles[role]);
      }),
    );
  }

  search(params: CrmDictionary, initial?: string | string[] | null) {
    return this.listUsers(params).pipe(
      switchMap(({ data }) => {
        if (!initial) {
          return of(data);
        }

        if (typeof initial === 'string') {
          const found = data.find(({ _id }) => _id === initial);

          if (found) {
            return of(data);
          }

          return this.getUser(initial).pipe(
            map((patient) => [patient, ...data]),
            catchError(() => of(data)),
          );
        }

        //TODO: load multiple users
        return of(data);
      }),
    );
  }

  override listUsers(params?: CrmSafeAny) {
    const hasSort = Object.keys(params || {}).some((key) =>
      /^\$sort*/.test(key),
    );
    return super.listUsers({
      ...(hasSort ? {} : { '$sort[lastName]': 1 }),
      ...params,
    });
  }

  listUsersData<Params>(params: Params) {
    return this.listUsers(params).pipe(map(({ data }) => data));
  }

  public listStaff(params?: CrmDictionary) {
    return this.listUsers({ 'roles.staff[$exists]': true, ...params });
  }

  public listAllStaff(params?: CrmDictionary) {
    const hasSort = Object.keys(params || {}).some((key) =>
      /^\$sort*/.test(key),
    );

    return super.listAllUsers({
      'roles.staff[$exists]': true,
      'roles.active': true,
      ...(hasSort ? {} : { '$sort[lastName]': 1 }),
      ...params,
    });
  }

  public listCustomers(
    params?: CrmDictionary,
  ): Observable<CrmEndpointListResponse<UserModel>> {
    return this.listUsers({ 'roles.customer[$exists]': true, ...params });
  }

  public listAllCustomers(params?: CrmDictionary): Observable<UserModel[]> {
    return this.listAllUsers({ 'roles.customer[$exists]': true, ...params });
  }

  public tryReLogin(): Observable<UserModel | null> {
    if (!this.token) {
      const extraParams = { queryParams: {} as CrmDictionary };
      if (this.loggedUser) {
        extraParams.queryParams['externalError'] = 'loggedOutSession';
      }
      const currentUrl = location.pathname;
      if (!['/auth/login', '/'].includes(currentUrl)) {
        extraParams.queryParams['returnUrl'] = currentUrl;
      }
      this.router.navigate(['auth', 'login'], extraParams);
      this.logout$.next();
      return of(null);
    }

    return this.login({ token: this.token });
  }

  emailTaken(email: string, existing?: string): Observable<boolean> {
    if (!email?.length || (existing && existing === email)) {
      return of(false);
    }

    return this.listUsers({ email }).pipe(map(({ data }) => data.length > 0));
  }

  resolveUser(user?: string | UserModel) {
    if (!user) {
      return throwError(() => new Error('Undefined user to resolve!'));
    }

    if (typeof user === 'string') {
      return this.getUser(user).pipe(
        catchError(() =>
          throwError(() => new Error(`User with ID: ${user} not found!`)),
        ),
      );
    }

    return of(user);
  }

  resolveUserWithUndefinedOnError(user?: string | UserModel) {
    return this.resolveUser(user).pipe(catchError(() => of(undefined)));
  }

  resolveUsers(users?: string[] | UserModel[]) {
    if (!users) {
      return of([] as UserModel[]);
    }

    if (typeof users?.[0] === 'string') {
      return safeListUsersByIDs(this, users as string[]);
    }

    return of(users as UserModel[]);
  }

  checkDeviceAvailable(
    params: CrmDictionary,
  ): Observable<{ available: boolean; device?: UserModel }> {
    return this.listUsers({ limit: 1, ...params }).pipe(
      map((users) => ({
        available: users.total > 0,
        device: users.total === 1 ? users.data[0] : undefined,
      })),
      tap(({ available }) => {
        if (!available) {
          this.messageService.info('generic.noDevice');
        }
      }),
    );
  }

  private withDomain<Body>(obj: Body): CrmSafeAny {
    return { ...obj, domain: environment.domain ?? location.hostname };
  }
}
